dd = function($var) {
	console.log($var);
};

$(document).foundation('topbar');
$(document).foundation('equalizer');
// $(document).foundation({
// 	abide: {
// 		live_validate: false,
// 		validate_on_blur: false,
// 		focus_on_invalid: false,
// 		error_labels: false
// 	}
// });

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// Initialize FastClick
FastClick.attach(document.body);

$(function(){

	if ($(window).width() > 769) {
		$('.faq_questions').sticky({ getWidthFrom: '.faq_menu_panel', topSpacing: 150 });
	}

	if ( $('html').hasClass('lt-ie9') ) {
		outdatedBrowser({
			bgColor: '#f25648',
			color: '#ffffff',
			lowerThan: 'boxShadow',
			languagePath: '../plugins/outdated-browser/outdatedbrowser/lang/en.html'
		});
	}

	if ("onpropertychange" in document && !!window.matchMedia) {
		$('html').addClass('lt-ie11');
	}
	if (!(window.ActiveXObject) && "ActiveXObject" in window) {
		$('html').addClass('lt-ie12');
	}

	isMobile.any ? $('html').addClass('no-autoplay') : '';

	// Initialize jQuery Placeholder
	$('input, textarea').placeholder();

	// Build videos with Mira Video converter in ogg, mp4 and webm
	$('#home-video-bg').vide('../videos/gametruck_excitement');

	$('.franchise-rating').each(function(i){
		var rating = $(this).attr('data-rating');

		$(this).raty({
			starType: 'i',
			score: rating,
			readOnly: true,
			starType: 'img',
			starHalf: '/images/star-half.png',
			starOff: '/images/star-off.png',
			starOn: '/images/star-on.png',
			hints: ['one star', 'two star', 'three star', 'four star', 'five star']
		});
	});

	$('[data-action="toggle-category-ratings"]').on('click', function(e){
		e.preventDefault();
		$('.category-ratings').slideToggle('fast');
	});

	$('#game-wall-grid').gridrotator({
		heightToWidthRatio: 1.25,
		rows: 3,
		columns: 12,
		preventClick: false,
		interval: 1000,
		animSpeed: 300,
		w480: {
			rows: 4,
			columns: 5
		},
		w320: {
			rows: 4,
			columns: 4
		}
	});


	$('#gallery-1').royalSlider({
		addActiveClass: true,
		arrowsNav: true,
		arrowsNavAutoHide:	true,
		arrowsNavHideOnTouch: true,
		controlNavigation: 'none',
		autoScaleSlider: true,
		autoScaleSliderWidth: 960,
		autoScaleSliderHeight: 385,
		imageScalePadding: 2,
		loop: true,
		fadeinLoadedSlide: true,
		globalCaption: true,
		keyboardNavEnabled: true,
		globalCaptionInside: false,

		visibleNearby: {
			enabled: true,
			centerArea: 0.5,
			center: true,
			breakpoint: 768,
			breakpointCenterArea: 0.75,
			navigateByCenterClick: true
		}
	}).data('royalSlider');


	$('[data-action="toggle-topbar"]').on('click', function(e) {
		e.preventDefault();
		$('.toggle-topbar').trigger('click');
	});

	$('[data-action-open]').on('click', function(e) {
		e.preventDefault();

		var $this = $(this),
			t = $this.attr('data-action-open');
		$('.icon-bar-holder').find('.icon-bar-drawer').not('.'+t).removeClass('active');
		$('.'+t).toggleClass('active');
	});

	// Hide Header on on scroll down
	var didScroll,
		lastScrollTop = 0,
		delta = 5,
		navbarHeight = $('.scroll-header').outerHeight(),
		interiorHeaderHeight = $('.interior-header').outerHeight();

	$(window).scroll(function(event){
		didScroll = true;
	});

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
		if(Math.abs(lastScrollTop - st) <= delta)
			return;

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if ($(window).scrollTop() > interiorHeaderHeight){
			// Scroll Down
			$('.scroll-header').removeClass('nav-up').addClass('nav-down');

		} else {
			// Scroll Up
			if(st + $(window).height() < $(document).height()) {
				$('.scroll-header').removeClass('nav-down').addClass('nav-up');
			}
		}

		lastScrollTop = st;
	}

	if ( ! isMobile.any ) {
		$('.fdatepicker').each(function() {
			var el = $(this)
			var val = el.val();

			el.attr('type', 'text');
			if (val) {
				el.val(moment(val, 'YYYY-MM-DD').format('MM/DD/YYYY'));
			}
			el.fdatepicker();

		})

	} else {
		// $('.fdatepicker').attr('type', 'date');
	}

	// if ( $('[type="date"]').length && $('[type="date"]').prop('type') != 'date' ) {
	// 	$('[type="text"]').fdatepicker();
	// }
});

$(window).on('load', function(){

	if($(window).width() > 767){
		$('input[name="phone"]').prop('pattern','^(\\(?[0-9]{3}\\)?(?:-|.|\\s)?)([0-9]{3}(?:-|.|\\s)[0-9]{4}|[0-9]{7})|[0-9]{10}$');
		// $('form[data-abide]').foundation('abide');
	}

});
